import * as React from "react";

function SvgComponent(props) {
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            width="90" height="90" viewBox="0 0 1024.000000 1024.000000"
            preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,1024.000000) scale(0.100000,-0.100000)"
                fill="#000000" stroke="none">
                <path d="M4825 7742 c-148 -76 -472 -241 -720 -366 -247 -126 -580 -295 -740
-377 -159 -81 -432 -220 -605 -308 l-315 -160 0 -425 0 -426 143 0 c136 0 146
1 188 26 l44 26 0 -1466 0 -1466 -112 -2 c-97 -1 -104 -2 -53 -8 33 -4 55 -7
50 -8 -6 -1 -11 -11 -11 -21 0 -16 11 -20 66 -26 86 -10 4443 -8 4670 1 150 7
167 9 123 17 -50 8 -74 27 -35 27 14 0 15 2 2 10 -8 5 -39 10 -67 10 l-53 0 0
1466 0 1466 44 -26 c42 -25 52 -26 185 -26 l141 0 -2 428 -3 427 -175 88 c-96
49 -474 241 -840 427 -901 457 -1641 830 -1649 830 -3 0 -127 -62 -276 -138z
m1204 -689 c487 -245 1003 -506 1146 -579 l260 -133 3 -125 c1 -69 0 -126 -4
-126 -3 0 -182 89 -398 199 -215 109 -738 372 -1162 586 l-771 387 -574 -290
c-1545 -780 -1748 -882 -1754 -882 -3 0 -4 57 -3 128 l3 127 130 68 c160 83
1400 710 1854 938 291 146 337 166 359 158 14 -6 424 -211 911 -456z m59 -648
l972 -485 0 -1430 0 -1430 -1955 0 -1955 0 0 1430 0 1430 703 351 c687 345
1245 619 1258 619 3 0 443 -218 977 -485z"/>
                <path d="M5037 6559 c-20 -6 -59 -31 -86 -55 -63 -57 -85 -111 -79 -196 5 -79
41 -139 112 -183 43 -26 59 -30 120 -30 118 1 200 61 232 172 21 72 7 144 -39
205 -57 74 -169 112 -260 87z"/>
                <path d="M4100 5786 c-86 -16 -162 -56 -225 -120 -68 -67 -101 -126 -125 -221
-19 -76 -20 -111 -20 -1062 l0 -983 135 0 135 0 0 1030 c0 1013 0 1031 20
1070 13 26 34 47 60 60 38 20 57 20 1022 20 l984 0 44 -22 c25 -13 55 -38 67
-57 l23 -34 0 -1033 0 -1034 130 0 130 0 0 993 c0 1080 2 1045 -56 1159 -58
114 -200 217 -329 238 -92 15 -1912 11 -1995 -4z"/>
                <path d="M4297 5279 l-47 -62 0 -168 0 -169 135 0 135 0 0 106 c0 80 -5 125
-21 178 -12 39 -27 94 -33 121 l-11 50 -55 3 -55 3 -48 -62z"/>
                <path d="M4524 5288 c4 -29 17 -82 29 -118 l22 -65 88 -3 c79 -3 87 -5 87 -22
0 -18 -7 -20 -90 -20 l-90 0 0 -90 0 -90 120 0 120 0 0 171 0 171 -46 59 -45
59 -101 0 -101 0 7 -52z"/>
                <path d="M5100 5310 c-26 -26 -26 -70 1 -104 l20 -26 410 0 c396 0 410 1 429
20 28 28 26 81 -3 108 -23 22 -26 22 -430 22 -394 0 -408 -1 -427 -20z"/>
                <path d="M5105 4975 c-16 -15 -25 -36 -25 -55 0 -19 9 -40 25 -55 l24 -25 399
0 c389 0 400 1 426 21 20 16 26 29 26 59 0 30 -6 43 -26 59 -26 20 -37 21
-426 21 l-399 0 -24 -25z"/>
                <path d="M4257 4643 c-36 -41 -25 -120 18 -137 9 -3 147 -6 306 -6 l289 0 0
80 0 80 -298 0 c-272 0 -300 -2 -315 -17z"/>
                <path d="M5119 4647 c-42 -32 -49 -81 -18 -121 l20 -26 410 0 c396 0 410 1
429 20 31 31 27 93 -6 119 -26 20 -37 21 -423 21 -295 -1 -400 -4 -412 -13z"/>
                <path d="M4260 3810 l0 -470 65 0 65 0 0 470 0 470 -65 0 -65 0 0 -470z" />
                <path d="M4480 3810 l0 -470 95 0 95 0 0 470 0 470 -95 0 -95 0 0 -470z" />
                <path d="M4762 3808 l3 -473 93 -2 92 -2 0 474 0 475 -95 0 -95 0 2 -472z" />
                <path d="M5002 3809 l3 -472 112 -1 113 -1 0 473 0 472 -115 0 -115 0 2 -471z" />
                <path d="M5290 3805 l0 -475 90 2 90 3 0 473 0 472 -90 0 -90 0 0 -475z" />
                <path d="M5562 3808 l3 -471 87 -1 88 -1 0 473 0 472 -90 0 -90 0 2 -472z" />
                <path d="M5830 3812 c0 -258 2 -471 4 -473 2 -2 32 -2 65 -1 l61 4 0 469 0
469 -65 0 -65 0 0 -468z"/>
                <path d="M3425 2610 c-192 -3 -335 -6 -317 -8 26 -2 32 -6 32 -26 l0 -24 508
-7 c719 -11 3410 0 3442 13 14 6 19 11 13 11 -20 1 -15 29 5 33 77 17 -2694
23 -3683 8z"/>
                <path d="M3895 2413 c28 -2 54 -8 60 -14 5 -5 17 -9 27 -9 10 0 18 -4 18 -8 0
-13 1841 -25 1874 -12 15 5 59 13 97 16 42 4 65 10 57 15 -7 5 28 10 82 12 52
1 -436 3 -1085 3 -649 0 -1157 -1 -1130 -3z"/>
            </g>
        </svg>
    )
}

export default SvgComponent;