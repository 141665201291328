import React from "react";
import styled from "styled-components";
// Components
// Assets
import HeaderImage from "../../assets/img/header.png";
import QuotesIcon from "../../assets/svg/Quotes";
import Dots from "../../assets/svg/Dots";
import AppstoreImage from "../../assets/img/appstore.png"
import GooglePlayImage from "../../assets/img/google_play.png"

export default function Header() {

  const openAppstore = () => {
    window.open("https://apps.apple.com/co/app/tin-pos-factura-electr%C3%B3nica/id6499087124")
  }

  const openGooglePlay = () => {
    window.open("https://play.google.com/store/apps/details?id=co.com.devapps.tinpos&pcampaignid=web_share")
    
  }

  return (
    <Wrapper id="home" className="container flexSpaceCenter">
      <LeftSide className="flexCenter">
        <div>
          <h1 className="extraBold font60">Genera Facturas Electrónicas con Tin Pos desde tu Móvil.</h1>
          <HeaderP className="font13 semiBold">
            Agiliza tu contabilidad y cumple con la normativa colombiana sin complicaciones. Tin Pos te permite emitir facturas electrónicas directamente desde tu móvil, garantizando cumplimiento y eficiencia. Descarga nuestra app y empieza a facturar de manera rápida y segura.
          </HeaderP>
          <div className="row-fluid w-100">
            <img className="col pointer" src={AppstoreImage} alt="Tin Pos Disponible en Appstore" style={{height: "56px"}} onClick={openAppstore}/>
            <div style={{width: 8}}/>
            <img className="col pointer" src={GooglePlayImage} alt="Tin Pos Disponible en Google Play" style={{height: "56px"}} onClick={openGooglePlay}/>
          </div>
        </div>
      </LeftSide>
      <RightSide>
        <ImageWrapper>
          <Img className="radius8" src={HeaderImage} alt="Imagen de la cabecera de tin pos factura electrónica mostrando una tienda de ropa de fondo con una captura de pantalla del inicio de la aplicación Tin Pos, destacando su interfaz intuitiva y fácil de usar." width={426} style={{zIndex: 9}} />
          <QuoteWrapper className="flexCenter quoteBackgroundColor radius8">
            <QuotesWrapper>
              <QuotesIcon />
            </QuotesWrapper>
            <div>
              <p className="font15 whiteColor">
                <em>Gracias a Tin Pos, facturar desde mi móvil es rápido y cumple con la DIAN. ¡Un cambio positivo para mi negocio!</em>
              </p>
              <p className="font13 whiteColor textRight" style={{marginTop: '10px'}}>Laura C., Boutique Flores.</p>
            </div>
          </QuoteWrapper>
          <DotsWrapper>
            <Dots />
          </DotsWrapper>
        </ImageWrapper>
        <GreyDiv className="lightBg"></GreyDiv>
      </RightSide>
    </Wrapper>
  );
}


const Wrapper = styled.section`
  padding-top: 80px;
  width: 100%;
  min-height: 840px;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;
const LeftSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 2;
    margin: 50px 0;
    text-align: center;
  }
  @media (max-width: 560px) {
    margin: 80px 0 50px 0;
  }
`;
const RightSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 1;
    margin-top: 30px;
  }
`;
const HeaderP = styled.div`
  max-width: 470px;
  padding: 15px 0 50px 0;
  line-height: 1.5rem;
  @media (max-width: 960px) {
    padding: 15px 0 50px 0;
    text-align: center;
    max-width: 100%;
  }
`;
const GreyDiv = styled.div`
  width: 30%;
  height: 700px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
  @media (max-width: 960px) {
    display: none;
  }
`;
const ImageWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 9;
  @media (max-width: 960px) {
    width: 100%;
    justify-content: center;
  }
`;
const Img = styled.img`
  @media (max-width: 560px) {
    width: 80%;
    height: auto;
  }
`;
const QuoteWrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: 50px;
  max-width: 330px;
  padding: 30px;
  z-index: 99;
  @media (max-width: 960px) {
    left: 20px;
  }
  @media (max-width: 560px) {
    bottom: -50px;
  }
`;
const QuotesWrapper = styled.div`
  position: absolute;
  left: -20px;
  top: -10px;
`;
const DotsWrapper = styled.div`
  position: absolute;
  right: -100px;
  bottom: 100px;
  z-index: 2;
  @media (max-width: 960px) {
    right: 100px;
  }
  @media (max-width: 560px) {
    display: none;
  }
`;


