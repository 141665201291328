import * as React from "react";

function SvgComponent(props) {
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
        width="90" height="90" viewBox="0 0 1024.000000 1024.000000"
        preserveAspectRatio="xMidYMid meet">
       
       <g transform="translate(0.000000,1024.000000) scale(0.100000,-0.100000)"
       fill="#000000" stroke="none">
       <path d="M4880 7665 c-257 -48 -429 -137 -614 -315 -254 -246 -368 -539 -353
       -909 11 -264 79 -461 232 -676 90 -127 280 -287 430 -362 72 -37 199 -83 226
       -83 10 0 19 -3 19 -8 0 -4 -82 -24 -182 -45 -405 -85 -721 -221 -968 -418
       -285 -226 -533 -579 -675 -958 -95 -255 -135 -451 -142 -706 -4 -151 -2 -174
       14 -205 10 -19 29 -40 43 -47 20 -10 268 -13 1138 -13 l1112 0 0 23 c0 64 -30
       212 -48 236 l-19 26 -977 3 c-969 2 -976 2 -976 22 0 39 31 227 56 339 102
       457 384 868 777 1134 81 55 276 156 372 192 94 35 308 91 425 110 216 36 538
       29 750 -16 158 -34 365 -108 515 -184 131 -66 201 -110 448 -278 l97 -67 135
       0 c73 0 136 4 139 9 17 27 -228 283 -379 397 -251 189 -528 311 -933 412
       l-153 38 73 23 c209 67 434 232 579 423 71 96 160 273 189 378 27 100 50 259
       50 344 0 150 -48 377 -109 511 -71 157 -226 347 -371 456 -74 56 -253 147
       -350 178 -153 49 -414 66 -570 36z m465 -290 c298 -88 519 -313 611 -622 36
       -119 44 -325 19 -458 -66 -358 -322 -652 -650 -751 -77 -23 -109 -27 -225 -28
       -157 -1 -228 14 -359 75 -257 120 -444 353 -522 654 -34 133 -33 341 3 476 78
       292 296 539 558 635 170 61 398 69 565 19z"/>
       <path d="M3081 6369 c-227 -40 -428 -233 -487 -469 -24 -95 -16 -261 18 -357
       51 -144 158 -270 285 -336 62 -31 78 -47 48 -47 -25 0 -220 -76 -307 -121
       -286 -143 -474 -364 -538 -632 -27 -109 -37 -285 -20 -327 24 -58 36 -60 398
       -60 l329 0 63 133 c172 366 389 652 660 869 41 33 76 64 78 68 3 9 -161 70
       -189 70 -32 0 -21 14 29 37 66 31 172 131 226 211 164 248 132 578 -78 788
       -39 39 -92 83 -119 98 -113 67 -274 97 -396 75z"/>
       <path d="M6895 6364 c-204 -45 -380 -203 -453 -407 -23 -63 -26 -87 -26 -197
       0 -108 4 -136 27 -206 47 -141 159 -281 279 -347 56 -31 71 -47 45 -47 -25 0
       -207 -61 -207 -69 0 -5 35 -34 78 -66 103 -77 321 -298 404 -410 81 -108 152
       -226 265 -437 l85 -158 322 0 c419 0 404 -7 393 199 -9 173 -74 359 -171 495
       -91 127 -261 265 -421 340 -56 27 -193 81 -284 112 -9 3 4 14 35 28 27 13 78
       46 112 75 205 171 278 423 197 679 -92 291 -398 478 -680 416z"/>
       <path d="M6243 4408 c-11 -13 -26 -60 -63 -185 -20 -70 -21 -71 -59 -78 -21
       -3 -69 -22 -106 -40 l-67 -34 -95 64 c-52 36 -100 65 -107 65 -21 0 -196 -185
       -196 -207 0 -11 29 -59 65 -106 l66 -86 -29 -58 c-16 -32 -37 -82 -46 -111
       -13 -37 -24 -53 -39 -57 -12 -3 -67 -14 -122 -25 -55 -11 -104 -25 -110 -31
       -16 -16 -13 -268 4 -278 11 -7 172 -37 240 -44 8 -1 19 -20 26 -43 7 -22 27
       -73 45 -111 l32 -71 -66 -92 c-36 -51 -66 -99 -66 -107 0 -19 188 -213 206
       -213 7 0 56 34 108 74 l95 75 65 -34 c36 -19 81 -37 100 -40 40 -8 45 -18 76
       -145 12 -46 28 -99 35 -117 l14 -33 125 0 125 0 20 68 c11 37 30 104 41 148
       l22 82 44 7 c24 3 69 19 101 35 32 17 61 30 65 30 4 0 43 -29 87 -64 127 -103
       110 -103 218 6 56 57 93 103 93 115 0 12 -27 62 -61 113 l-60 93 29 66 c16 36
       34 87 41 112 l13 47 110 16 c173 27 159 11 156 173 l-3 138 -70 17 c-38 9 -96
       22 -128 30 l-59 13 -32 85 c-17 47 -37 94 -43 105 -10 16 -3 31 43 96 87 123
       87 115 -5 217 -43 48 -90 93 -104 101 -25 13 -30 11 -113 -48 -48 -35 -93 -65
       -100 -68 -8 -3 -33 6 -57 20 -23 14 -69 34 -102 45 -67 23 -53 -1 -117 202
       l-23 75 -126 3 c-93 2 -129 -1 -136 -10z m262 -480 c195 -40 376 -218 416
       -406 7 -34 9 -94 6 -147 -17 -299 -259 -524 -562 -525 -195 0 -388 116 -478
       287 -48 91 -67 169 -67 268 0 142 44 255 138 357 143 154 331 211 547 166z"/>
       <path d="M6580 3502 c-80 -82 -162 -164 -182 -182 l-38 -32 -80 71 c-102 90
       -122 92 -191 22 -27 -26 -49 -57 -49 -68 0 -11 50 -69 124 -142 l125 -123 63
       7 63 6 213 213 212 213 0 50 c0 39 -5 54 -22 70 -27 24 -61 43 -80 43 -7 0
       -78 -67 -158 -148z"/>
       </g>
       </svg>
    )
}

export default SvgComponent;