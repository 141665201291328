import React from "react";
import styled from "styled-components";
// Components
import ProjectBox from "../Elements/ProjectBox";
import FullButton from "../Buttons/FullButton";
// Assets
import MakeSale from "../../assets/img/ui/make-sale.png";
import CashRegister from "../../assets/img/ui/cash-register.png";
import Inventory from "../../assets/img/ui/inventory.png";

import Users from "../../assets/img/ui/users.png";
import Customers from "../../assets/img/ui/customers.png";
import Stats from "../../assets/img/ui/stats.png";

import DiscountImage from "../../assets/img/discount.png";

export default function Projects() {

  const openContactPage = () => {
    window.open("https://wa.link/oz0m2x");
  }

  return (
    <Wrapper id="projects">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Interfaz Intuitiva y Venta sin Complicaciones</h1>
            <p className="font13">
            Con Tin Pos, gestionar tu facturación electrónica nunca ha sido tan fácil. Nuestra interfaz intuitiva está diseñada para que cualquier usuario pueda operar sin complicaciones.
              <br />
              Descubre cómo Tin Pos puede transformar tu negocio con su simplicidad y eficiencia.
            </p>
          </HeaderInfo>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={MakeSale}
                title="Selección Rápida de Productos"
                text="Elige productos rápidamente con nuestra interfaz intuitiva. Simplifica el proceso de venta y mejora la eficiencia en tu punto de venta."
                action={() => alert("clicked")}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={CashRegister}
                title="Gestión Eficiente de Caja"
                text="Gestiona fácilmente la apertura y cierre de caja. Controla tus transacciones diarias con unos pocos clics y mantén un registro preciso."
                action={() => alert("clicked")}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={Inventory}
                title="Control de Inventarios en Tiempo Real"
                text="Mantén tu inventario organizado y actualizado en tiempo real. Gestiona tus productos de manera eficiente y evita desabastecimientos."
                action={() => alert("clicked")}
              />
            </div>
          </div>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={Users}
                title="Administración de Usuarios y Roles"
                text="Asigna y gestiona permisos de usuarios con facilidad. Asegura el control y la seguridad en las operaciones de tu equipo."
                action={() => alert("clicked")}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={Customers}
                title="Gestión de Clientes Simplificada"
                text="Administra la información de tus clientes de forma sencilla. Mejora la atención y fidelización con datos siempre disponibles."
                action={() => alert("clicked")}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={Stats}
                title="Análisis de Estadísticas y Reportes"
                text="Accede a estadísticas y reportes detallados. Analiza el rendimiento de tu negocio y toma decisiones informadas para su crecimiento."
                action={() => alert("clicked")}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="lightBg">
        <div className="container">
          <Advertising className="flexSpaceCenter">
            <AddLeft>
              <AddLeftInner>
                <ImgWrapper className="flexCenter">
                  <img className="radius8" src={DiscountImage} alt="add" />
                </ImgWrapper>
              </AddLeftInner>
            </AddLeft>
            <AddRight>
              <h4 className="font15 semiBold">Que no te coja la tarde</h4>
              <h2 className="font40 extraBold">Empieza Hoy con la Facturación Electrónica de la DIAN</h2>
              <p className="font12">
              No esperes más para cumplir con las normativas de la DIAN. Adquiere tu membresía de Tin Pos y transforma la manera en que gestionas tu facturación. Con nuestro sistema, podrás generar y enviar facturas electrónicas de manera fácil y segura, asegurando el cumplimiento total con las regulaciones colombianas.
              </p>
              <ButtonsRow className="flexNullCenter" style={{ margin: "30px 0" }}>
                <div style={{ width: "190px" }}>
                  <FullButton title="Solicitar información" action={openContactPage} />
                </div>
               
              </ButtonsRow>
            </AddRight>
          </Advertising>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  padding: 100px 0;
  margin: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 60px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  position: relative;
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
  }
`;
const AddLeftInner = styled.div`
  width: 100%;
  position: absolute;
  top: -300px;
  left: 0;
  @media (max-width: 1190px) {
    top: -250px;
  }
  @media (max-width: 920px) {
    top: -200px;
  }
  @media (max-width: 860px) {
    order: 1;
    position: relative;
    top: -60px;
    left: 0;
  }
`;
const ImgWrapper = styled.div`
  width: 100%;
  padding: 0 15%;
  img {
    width: 100%;
    height: auto;
  }
  @media (max-width: 400px) {
    padding: 0;
  }
`;
