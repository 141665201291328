import * as React from "react";

function SvgComponent(props) {
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            width="90" height="90" viewBox="0 0 1024.000000 1024.000000"
            preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,1024.000000) scale(0.100000,-0.100000)"
                fill="#000000" stroke="none">
                <path d="M3615 7798 c-93 -17 -174 -65 -261 -152 -89 -89 -132 -165 -154 -271
-10 -47 -13 -526 -14 -2260 l-1 -2200 -182 -3 c-171 -2 -183 -1 -183 16 0 19
103 505 165 777 46 200 90 416 106 518 13 85 7 612 -9 676 -9 40 -22 -15 -162
-659 -130 -599 -166 -763 -250 -1143 -44 -196 -80 -359 -80 -362 0 -3 398 -5
884 -5 l884 0 68 -32 69 -33 588 -3 588 -2 71 35 72 35 873 0 c597 0 873 3
873 10 0 10 -88 423 -230 1075 -34 154 -74 343 -90 420 -83 402 -157 728 -159
695 -1 -8 -5 -53 -11 -100 -5 -47 -10 -195 -11 -330 -1 -268 -1 -268 91 -690
89 -406 123 -562 155 -719 19 -95 35 -175 35 -177 0 -2 -81 -4 -180 -4 -135 0
-182 3 -185 13 -3 6 -6 892 -8 1967 -2 1547 -5 1963 -15 1992 -26 75 -94 154
-432 496 -266 269 -357 355 -397 376 -116 58 -65 56 -1313 55 -678 -1 -1165
-5 -1195 -11z m2217 -480 c3 -296 4 -304 27 -348 30 -58 253 -281 308 -309 40
-20 57 -21 323 -21 l280 0 0 -1865 0 -1865 -1695 0 -1695 0 0 2183 c0 1790 3
2191 14 2232 37 139 121 231 255 280 29 11 245 14 1108 14 l1071 1 4 -302z
m916 -470 c13 -17 4 -18 -236 -18 l-249 0 -106 109 c-58 61 -112 120 -119 133
-11 18 -13 72 -10 267 l4 244 351 -358 c194 -197 358 -366 365 -377z"/>
                <path d="M3737 7252 c-23 -25 -21 -64 3 -90 20 -21 30 -22 165 -22 137 0 145
1 159 22 20 29 20 72 0 92 -13 13 -42 16 -163 16 -131 0 -150 -2 -164 -18z"/>
                <path d="M4915 7105 c-83 -16 -129 -32 -216 -75 -66 -34 -214 -142 -222 -163
-9 -23 23 -83 53 -101 l30 -17 42 40 c66 61 92 79 165 115 186 92 424 91 608
-1 66 -34 154 -94 184 -127 32 -34 42 -33 86 8 51 49 45 68 -45 143 -190 160
-439 224 -685 178z"/>
                <path d="M4913 6835 c-61 -17 -121 -46 -178 -86 -50 -35 -95 -77 -95 -89 0
-14 70 -90 82 -90 5 0 29 16 51 36 57 48 148 90 229 105 118 22 257 -17 371
-105 26 -20 54 -36 62 -36 15 0 68 63 71 86 5 33 -117 120 -232 164 -76 30
-279 38 -361 15z"/>
                <path d="M4970 6581 c-75 -24 -160 -86 -160 -115 0 -21 58 -86 77 -86 8 0 29
11 47 25 98 75 162 72 297 -12 24 -15 27 -15 56 6 17 13 36 34 42 47 14 32
-22 74 -96 111 -47 23 -72 28 -147 30 -50 1 -102 -1 -116 -6z"/>
                <path d="M5014 6305 c-41 -21 -64 -64 -64 -120 0 -40 5 -52 34 -81 29 -29 41
-34 80 -34 83 0 136 47 136 120 0 91 -105 156 -186 115z"/>
                <path d="M3747 6138 c-9 -7 -19 -25 -22 -40 -5 -21 -1 -33 19 -52 l25 -26 253
0 c289 0 298 2 298 69 0 20 -5 42 -12 49 -17 17 -538 17 -561 0z"/>
                <path d="M5857 6132 c-22 -24 -21 -54 2 -83 19 -24 20 -24 280 -27 l261 -3 25
25 c30 30 32 59 5 86 -19 19 -33 20 -288 20 -248 0 -270 -1 -285 -18z"/>
                <path d="M3745 5645 c-30 -29 -32 -58 -5 -85 20 -20 33 -20 780 -20 747 0 760
0 780 20 27 27 25 62 -3 88 l-23 22 -753 0 -752 0 -24 -25z"/>
                <path d="M5652 5654 c-28 -19 -30 -74 -4 -97 16 -15 51 -17 285 -17 254 0 268
1 287 20 20 20 26 56 12 76 -20 30 -55 34 -304 34 -213 0 -257 -2 -276 -16z"/>
                <path d="M3745 5106 c-33 -33 -32 -69 1 -95 25 -20 39 -21 229 -21 179 0 205
2 223 18 32 26 37 64 14 93 -20 24 -21 24 -231 27 l-211 3 -25 -25z"/>
                <path d="M4410 5117 c-43 -22 -40 -95 5 -115 17 -8 88 -12 200 -12 162 0 177
2 195 20 23 23 26 68 6 96 -13 17 -29 19 -198 22 -135 2 -190 -1 -208 -11z"/>
                <path d="M5156 5104 c-22 -22 -20 -70 4 -94 20 -20 33 -20 623 -20 656 0 637
-2 651 56 5 17 1 35 -9 50 l-15 24 -620 0 c-549 0 -620 -2 -634 -16z"/>
                <path d="M3762 4598 c-45 -22 -41 -98 5 -117 21 -8 329 -11 1159 -9 1097 3
1133 4 1153 22 30 27 27 70 -5 95 -27 21 -28 21 -1158 20 -797 0 -1138 -4
-1154 -11z"/>
                <path d="M5282 4208 c-7 -7 -12 -39 -12 -74 0 -90 6 -94 145 -94 106 0 115 1
129 22 10 13 16 43 16 74 0 77 -12 84 -151 84 -76 0 -119 -4 -127 -12z"/>
                <path d="M5686 4198 c-9 -12 -16 -43 -16 -68 0 -25 7 -56 16 -68 14 -21 22
-22 134 -22 108 0 120 2 130 19 14 27 13 134 -2 149 -8 8 -51 12 -129 12 -110
0 -119 -1 -133 -22z"/>
                <path d="M6097 4202 c-22 -24 -23 -110 -1 -140 14 -20 23 -22 120 -22 l104 0
0 -50 0 -50 -104 0 c-82 0 -108 -3 -120 -16 -21 -21 -21 -112 0 -142 14 -20
23 -22 120 -22 l104 0 0 -50 0 -50 -104 0 c-97 0 -106 -2 -120 -22 -9 -12 -16
-43 -16 -68 0 -25 7 -56 16 -68 14 -20 23 -22 120 -22 l104 0 0 -50 0 -50
-104 0 c-97 0 -106 -2 -120 -22 -10 -14 -16 -44 -16 -79 0 -84 9 -89 159 -89
l122 0 42 39 42 40 3 425 c3 471 3 467 -61 506 -27 17 -51 20 -153 20 -104 0
-123 -2 -137 -18z"/>
                <path d="M3741 4134 c-26 -33 -26 -55 -2 -85 l18 -23 597 0 c580 -1 598 0 612
18 20 28 17 73 -6 96 -20 20 -33 20 -609 20 l-590 0 -20 -26z"/>
                <path d="M5282 3928 c-7 -7 -12 -40 -12 -78 0 -38 5 -71 12 -78 8 -8 51 -12
129 -12 110 0 119 1 133 22 10 13 16 43 16 74 0 77 -12 84 -151 84 -76 0 -119
-4 -127 -12z"/>
                <path d="M5686 3918 c-9 -12 -16 -43 -16 -68 0 -25 7 -56 16 -68 14 -21 23
-22 133 -22 78 0 121 4 129 12 7 7 12 40 12 78 0 38 -5 71 -12 78 -8 8 -51 12
-129 12 -110 0 -119 -1 -133 -22z"/>
                <path d="M3740 3860 c-27 -27 -25 -56 5 -85 l24 -25 585 0 585 0 21 22 c25 28
26 62 0 88 -20 20 -33 20 -610 20 -577 0 -590 0 -610 -20z"/>
                <path d="M5282 3648 c-7 -7 -12 -40 -12 -78 0 -38 5 -71 12 -78 8 -8 51 -12
130 -12 l117 0 16 30 c19 38 19 99 -1 128 -14 21 -23 22 -133 22 -78 0 -121
-4 -129 -12z"/>
                <path d="M5679 3638 c-12 -23 -7 -135 8 -148 4 -4 59 -10 120 -14 104 -6 114
-5 133 14 15 15 20 33 20 76 0 93 -1 94 -145 94 -117 0 -125 -1 -136 -22z"/>
                <path d="M3736 3578 c-22 -31 -20 -64 4 -88 20 -20 33 -20 614 -20 l595 0 15
22 c23 33 20 74 -6 92 -20 14 -95 16 -615 16 l-592 0 -15 -22z"/>
                <path d="M5288 3369 c-15 -8 -18 -24 -18 -82 0 -44 5 -78 12 -85 8 -8 51 -12
129 -12 137 0 141 3 147 90 4 49 1 61 -17 79 -19 19 -32 21 -128 21 -60 0
-116 -5 -125 -11z"/>
                <path d="M5686 3358 c-10 -13 -16 -43 -16 -73 0 -30 6 -60 16 -73 14 -21 22
-22 134 -22 139 0 140 1 140 98 0 86 -10 92 -148 92 -103 0 -112 -2 -126 -22z"/>
                <path d="M3736 3298 c-21 -30 -20 -50 5 -82 l20 -26 590 0 c576 0 589 0 609
20 24 24 26 57 4 88 l-15 22 -599 0 -599 0 -15 -22z"/>
                <path d="M2988 5236 c-94 -34 -158 -88 -199 -167 -11 -22 -55 -178 -98 -347
-103 -404 -205 -799 -286 -1107 -184 -704 -209 -809 -208 -876 1 -124 72 -233
191 -291 l67 -33 2590 -3 c1953 -2 2604 0 2645 9 157 33 258 147 268 302 4 79
23 -1 -279 1177 -72 283 -169 660 -214 837 -45 178 -91 336 -103 355 -55 89
-164 151 -267 152 l-60 1 0 -95 0 -94 45 -8 c62 -11 97 -26 113 -52 20 -30 40
-97 91 -296 25 -96 83 -323 131 -505 47 -181 148 -580 225 -886 122 -488 138
-560 129 -592 -11 -43 -55 -82 -107 -97 -54 -15 -5107 -14 -5168 1 -65 17
-104 65 -104 128 0 49 141 623 355 1441 62 239 132 509 155 600 23 91 50 179
61 196 22 36 56 53 121 61 l49 6 -3 96 -3 96 -50 2 c-27 2 -66 -3 -87 -11z"/>
            </g>
        </svg>
    )
}

export default SvgComponent;