import React from "react";
import styled from "styled-components";
// Components
import PricingTable from "../Elements/PricingTable";

export default function Pricing() {

  const openContactPage = () => {
    window.open("https://wa.link/oz0m2x");
  }

  return (
    <Wrapper id="pricing">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Planes de Precios y Membresías</h1>
            <p className="font13">
              Selecciona el plan que mejor se adapte a tus necesidades y empieza a disfrutar de los beneficios de Tin Pos. Ofrecemos un plan gratuito para negocios que no necesitan facturación electrónica y un plan premium que cumple con todas las normativas de la DIAN para facturación electrónica.

              <br />
              <br />
              *La factura enviada al correo del cliente en el plan GRATIS no tiene ningún valor fiscal ante la DIAN.
            </p>
          </HeaderInfo>
          <TablesWrapper className="flexSpaceNull">
            <TableBox>
              <PricingTable
                icon="roller"
                price="$0 Pesos"
                title="Gratis"
                text={"Para negocios que NO necesitan facturación electrónica. pero quieren llevar control de su inventario, ventas y estadísticas"}
                offers={[
                  { name: "Ventas ilimitadas", cheked: true },
                  { name: "Envio de factura al correo*", cheked: true },
                  { name: "Manejo de caja", cheked: true },
                  { name: "Manejo de inventarios (Hasta 15 productos)", cheked: true },
                  { name: "Manejo de categorías", cheked: true },
                  { name: "Estadísticas de ventas", cheked: true },
                  { name: "Manejo de clientes", cheked: true },
                  { name: "Manejo de proveedores", cheked: true },
                  { name: "Manejo de impuestos", cheked: true },
                  { name: "Facturación electrónica", cheked: false },
                  { name: "Manejo de usuarios y roles", cheked: false },
                  { name: "Múltiples cajas", cheked: false },
                  { name: "Soporte personalizado", cheked: false },
                  { name: "Asesoría y configuración", cheked: false },
                  { name: "10% de Descuento", cheked: false },
                  { name: "Certificado digital gratis", cheked: false },

                ]}
                action={openContactPage}
              />
            </TableBox>
            <TableBox>
              <PricingTable
                icon="roller"
                price="$69.900 Pesos"
                title="Plan Oro Mensual"
                text={"Para negocios que necesitan facturar electrónicamente y desean una interfaz amigable sin límites."}
                offers={[
                  { name: "Ventas ilimitadas", cheked: true },
                  { name: "Envio de factura electrónica al correo", cheked: true },
                  { name: "Manejo de caja", cheked: true },
                  { name: "Manejo de inventarios", cheked: true },
                  { name: "Manejo de categorías", cheked: true },
                  { name: "Estadísticas de ventas", cheked: true },
                  { name: "Manejo de clientes", cheked: true },
                  { name: "Manejo de proveedores", cheked: true },
                  { name: "Manejo de impuestos", cheked: true },
                  { name: "Facturación electrónica", cheked: true },
                  { name: "Manejo de usuarios y roles", cheked: true },
                  { name: "Múltiples cajas", cheked: true },
                  { name: "Soporte personalizado", cheked: true },
                  { name: "Asesoría y configuración", cheked: true },
                  { name: "10% de Descuento", cheked: false },
                  { name: "Certificado digital gratis", cheked: false },

                ]}
                action={openContactPage}
              />
            </TableBox>
            <TableBox>
              <PricingTable
                icon="roller"
                price="$1.008.800 Pesos"
                priceWithDiscount="$759.900 Pesos"
                title="Plan Oro Anual"
                text={"Para negocios que necesitan facturar electrónicamente y desean una interfaz amigable sin límites."}
                offers={[
                  { name: "Ventas ilimitadas", cheked: true },
                  { name: "Envio de factura electrónica al correo", cheked: true },
                  { name: "Manejo de caja", cheked: true },
                  { name: "Manejo de inventarios", cheked: true },
                  { name: "Manejo de categorías", cheked: true },
                  { name: "Estadísticas de ventas", cheked: true },
                  { name: "Manejo de clientes", cheked: true },
                  { name: "Manejo de proveedores", cheked: true },
                  { name: "Manejo de impuestos", cheked: true },
                  { name: "Facturación electrónica", cheked: true },
                  { name: "Manejo de usuarios y roles", cheked: true },
                  { name: "Múltiples cajas", cheked: true },
                  { name: "Soporte personalizado", cheked: true },
                  { name: "Asesoría y configuración", cheked: true },
                  { name: "10% de Descuento", cheked: true },
                  { name: "Certificado digital gratis", cheked: true },

                ]}
                action={openContactPage}
              />
            </TableBox>
          </TablesWrapper>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding: 50px 0;
`;
const HeaderInfo = styled.div`
  margin-bottom: 50px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const TablesWrapper = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const TableBox = styled.div`
  width: 31%;
  @media (max-width: 860px) {
    width: 100%;
    max-width: 370px;
    margin: 0 auto
  }
`;




