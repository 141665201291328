import React from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
import LogoWhite from "../../assets/img/ic_logo_white.png"
import { Link as ReactRouterLink } from 'react-router-dom'

export default function Contact() {

  const getCurrentYear = () => {
    return new Date().getFullYear();
  }

  return (
    <Wrapper>
      <div className="darkBg">
        <div className="container">
          <InnerWrapper className="flexSpaceCenter" style={{ padding: "30px 0" }}>
            <div className="flexCenter animate">
              <img src={LogoWhite} alt="Tin Pos: Software Pos #1 en Colombia" style={{ height: "36px" }} />
              <h1 style={{ marginLeft: "15px", marginTop: "8px" }} className="font20 extraBold whiteColor">
                Tin Pos - Colombia 🇨🇴
              </h1>
            </div>
            <StyleP className="whiteColor font13">
              © {getCurrentYear()} - Devapps S.A.S Todos los derechos reservados
            </StyleP>
          </InnerWrapper>
        </div>
        <div className="container">
          <InnerWrapper className="flexSpaceCenter" style={{ padding: "30px 0" }}>
            <div>

            </div>
            <div className="flexSpaceCenter">
              <ReactRouterLink className="linkOnMain animate pointer font13" to="/politica-de-privacidad" smooth={true}>
                Política de privacidad
              </ReactRouterLink>
              <div style={{width: 16}}/>
              <ReactRouterLink className="linkOnMain animate pointer font13" to="/terminos-y-condiciones" smooth={true}>
                Términos y condiciones
              </ReactRouterLink>
              
            </div>
            <Link className="animate pointer font13 linkOnMain" to="home" smooth={true} offset={-80}>
              Volver arriba
            </Link>
          </InnerWrapper>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
`;
const InnerWrapper = styled.div`
  @media (max-width: 550px) {
    flex-direction: column;
  }
`;
const StyleP = styled.p`
  @media (max-width: 550px) {
    margin: 20px 0;
  }
`;