import * as React from "react";

function SvgComponent(props) {
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
        width="90" height="90" viewBox="0 0 1024.000000 1024.000000"
        preserveAspectRatio="xMidYMid meet">
       
       <g transform="translate(0.000000,1024.000000) scale(0.100000,-0.100000)"
       fill="#000000" stroke="none">
       <path d="M2825 8230 c-226 -46 -415 -217 -481 -435 -18 -57 -19 -174 -21
       -2429 -2 -1526 0 -2389 7 -2423 42 -232 230 -445 455 -516 l70 -22 892 0 892
       0 30 29 c39 37 48 83 27 128 -32 67 29 62 -946 68 l-885 5 -67 33 c-83 41
       -149 107 -191 191 l-32 66 -3 2389 -2 2390 22 66 c28 87 115 176 201 208 57
       22 66 22 723 22 542 0 671 3 698 14 101 42 101 170 0 212 -27 11 -153 14 -691
       13 -362 -1 -676 -5 -698 -9z"/>
       <path d="M6075 8229 c-54 -31 -75 -62 -75 -110 0 -41 4 -51 35 -78 20 -17 37
       -32 38 -33 1 -2 333 -5 737 -8 l735 -5 48 -23 c59 -30 129 -97 157 -152 14
       -27 25 -73 29 -124 9 -94 20 -120 64 -146 40 -25 73 -25 113 -1 61 37 76 108
       49 234 -40 190 -199 365 -390 429 l-70 23 -725 2 c-522 2 -731 0 -745 -8z"/>
       <path d="M5353 8049 c-48 -49 -96 -103 -106 -120 -25 -39 -16 -86 23 -124 24
       -23 35 -27 64 -22 l36 6 0 -894 0 -895 -1196 -2 -1196 -3 -24 -28 c-33 -38
       -33 -96 0 -134 l24 -28 1249 -3 c924 -2 1255 1 1276 9 60 25 57 -35 57 1021 0
       533 3 968 6 968 3 0 14 -5 24 -10 27 -15 66 4 90 42 39 65 26 90 -124 246 -84
       86 -92 85 -203 -29z"/>
       <path d="M4710 6867 c0 -533 3 -706 12 -715 14 -14 414 -17 436 -4 10 7 12
       156 10 713 l-3 704 -227 3 -228 2 0 -703z"/>
       <path d="M5981 7344 c-25 -21 -31 -33 -31 -65 0 -38 8 -48 140 -185 l140 -145
       -16 -31 c-105 -203 -71 -439 83 -590 l44 -43 30 35 c16 19 41 49 55 67 l25 31
       -34 46 c-52 67 -72 128 -72 216 0 62 6 86 28 131 36 75 98 135 177 173 56 26
       76 31 145 31 71 0 88 -4 147 -33 193 -95 262 -282 172 -463 -13 -25 -34 -60
       -48 -76 l-25 -30 160 -166 c166 -173 208 -207 253 -207 40 0 86 50 86 92 0 29
       -16 48 -130 163 l-130 130 27 65 c49 119 43 282 -16 403 l-28 58 139 149 c111
       120 138 155 138 179 0 39 -34 79 -75 87 -48 9 -79 -14 -215 -159 l-115 -123
       -54 33 c-91 55 -157 75 -261 80 -133 7 -253 -27 -339 -96 l-25 -19 -124 130
       c-162 167 -191 183 -251 132z"/>
       <path d="M7854 7270 c-11 -4 -33 -22 -47 -40 l-27 -32 0 -1655 c0 -1511 1
       -1658 16 -1689 41 -86 168 -84 209 2 13 28 15 227 15 1671 0 1572 -1 1640 -19
       1679 -27 58 -91 87 -147 64z"/>
       <path d="M4020 6680 c0 -503 1 -520 19 -530 30 -15 436 -13 449 3 6 8 8 193 4
       530 l-5 517 -233 0 -234 0 0 -520z"/>
       <path d="M3347 6833 c-4 -3 -7 -154 -7 -334 0 -284 2 -330 16 -343 13 -14 47
       -16 219 -16 112 0 210 3 219 6 14 5 16 46 16 350 l0 344 -228 0 c-126 0 -232
       -3 -235 -7z"/>
       <path d="M6635 6826 c-37 -16 -82 -68 -91 -102 -19 -76 26 -161 100 -189 60
       -23 107 -13 156 31 72 65 77 145 13 214 -50 56 -117 73 -178 46z"/>
       <path d="M6235 5953 c-256 -54 -444 -201 -523 -407 -12 -31 -17 -99 -22 -281
       -6 -238 -6 -240 -34 -295 -56 -108 -29 -222 69 -288 46 -31 50 -38 72 -114 48
       -165 129 -304 245 -420 73 -73 142 -117 238 -153 65 -25 89 -29 185 -29 94 0
       120 4 177 26 234 88 402 286 493 580 23 73 31 88 55 98 37 16 86 67 104 112
       23 53 19 104 -10 173 -25 56 -27 73 -33 275 -6 214 -7 215 -36 268 -16 30 -43
       65 -60 79 -44 38 -122 71 -186 78 -56 7 -57 7 -69 48 -29 100 -84 162 -185
       207 -129 59 -325 76 -480 43z"/>
       <path d="M3113 5275 c-31 -31 -35 -40 -29 -68 4 -17 20 -45 36 -62 l30 -30
       848 -3 c937 -3 915 -4 947 58 23 44 18 69 -19 106 l-34 34 -872 0 -872 0 -35
       -35z"/>
       <path d="M3153 4828 c-35 -9 -73 -50 -73 -80 0 -32 29 -77 60 -93 26 -13 154
       -15 1086 -13 l1056 3 29 33 c50 56 29 127 -44 151 -40 13 -2064 12 -2114 -1z"/>
       <path d="M3128 4355 c-33 -18 -52 -72 -39 -107 5 -14 22 -35 37 -47 l27 -21
       833 0 832 0 31 26 c26 21 31 33 31 69 0 36 -5 48 -31 69 l-31 26 -831 0 c-707
       -1 -836 -3 -859 -15z"/>
       <path d="M5609 3792 c-129 -114 -293 -258 -365 -320 -158 -138 -217 -206 -261
       -297 -59 -125 -64 -173 -61 -581 l3 -364 28 -27 27 -28 1458 -5 c1343 -5 1460
       -4 1499 12 73 29 73 31 73 415 -1 245 -5 360 -15 417 -19 105 -79 231 -146
       301 -79 83 -757 680 -777 683 -15 2 -27 -34 -80 -238 -165 -629 -245 -875
       -282 -875 -18 0 -43 96 -101 380 l-42 202 74 71 74 71 -49 50 c-67 68 -98 81
       -193 81 -90 0 -131 -17 -203 -83 -29 -27 -39 -43 -35 -56 3 -10 33 -45 67 -77
       l61 -58 -27 -130 c-52 -259 -101 -456 -113 -456 -18 0 -48 57 -76 145 -50 155
       -238 832 -262 943 -4 21 -12 32 -23 31 -9 0 -123 -93 -253 -207z m1710 -696
       c72 -37 89 -141 36 -218 -46 -66 -117 -98 -217 -98 -43 0 -78 4 -78 9 0 5 26
       32 57 60 54 49 56 51 39 70 -41 45 -27 125 30 170 32 25 92 28 133 7z"/>
       <path d="M3140 3882 c-65 -32 -76 -110 -23 -152 25 -20 40 -20 613 -20 576 0
       588 0 613 20 31 24 44 78 27 109 -6 12 -26 31 -43 41 -31 19 -55 20 -592 20
       -513 0 -563 -1 -595 -18z"/>
       <path d="M3128 3405 c-32 -18 -52 -72 -39 -106 5 -13 19 -33 31 -44 21 -19 38
       -20 526 -23 561 -3 552 -4 580 63 17 41 7 73 -32 104 -26 21 -31 21 -533 21
       -422 -1 -510 -3 -533 -15z"/>
       </g>
       </svg>
    )
}

export default SvgComponent;