import React, { useLayoutEffect, useState } from "react";

import styled from "styled-components";
// Assets
import ContactImg1 from "../assets/img/contact-1.png";
import ContactImg2 from "../assets/img/contact-2.png";
import ContactImg3 from "../assets/img/contact-3.png";
import TopNavbar from "../components/Nav/TopNavbar";
import axios from "axios";
import { useLocation } from "react-router-dom";


export default function Contact() {

    const location = useLocation();
    useLayoutEffect(() => {
      document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);

    const [name, setName] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [email, setEmail] = useState("")
    const [subject, setSubject] = useState("suscription")
    const [message, setMessage] = useState("")

    const [error, setError] = useState("")

    const [success, setSuccess] = useState("")

    const sendContactMessage = (event) => {
        if (name.trim() === "") {
            setError("Por favor escribe tu nombre.")
            return
        }

        if (phoneNumber.trim() === "") {
            setError("Por favor escribe tu número de teléfono.")
            return
        }

        if (email.trim() === "") {
            setError("Por favor escribe tu correo electrónico.")
            return
        }

        if (subject.trim() === "") {
            setError("Por favor selecciona un asunto.")
            return
        }

        if (message.trim() === "") {
            setError("Por favor escribe un mensaje.")
            return
        }

        let data = {
            name: name,
            phone_number: phoneNumber,
            email: email,
            subject: subject,
            message: message
        }

        setError("")

        axios.post("https://app.tinpos.com.co/api/contact", data, {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        }).then(response => {
            if(response.status === 200) {
                setSuccess("Mensaje enviado correctamente. Procesaremos la información y te daremos una respuesta en caso de ser necesario.")
                setName("")
                setPhoneNumber("")
                setEmail("")
                setSubject("suscription")
                setMessage("")
                
            } else {
                setError("Ocurrió un error enviando el mensaje, intenta nuevamente más tarde.")
            }
        }).catch(err => {
            console.log(err)
            setError(err.toString())
        })
    }

    const onChangeName = (event) => {
        setError("")
        setSuccess("")
        setName(event.target.value)
    }

    const onChangePhoneNumber = (event) => {
        setError("")
        setSuccess("")
        setPhoneNumber(event.target.value)
    }

    const onChangeEmail = (event) => {
        setError("")
        setSuccess("")
        setEmail(event.target.value)
    }

    const onChangeSubject = (event) => {
        setError("")
        setSuccess("")
        setSubject(event.target.value)
    }

    const onChangeMessage = (event) => {
        setError("")
        setSuccess("")
        setMessage(event.target.value)
    }

    return <>
        <TopNavbar />
        <Wrapper className="container" style={{ paddingBottom: "24px" }}>
            <div>
                <div className="container">

                    <HeaderInfo>
                        <h1 className="font40 extraBold">Contacto</h1>
                        <p className="font13">
                            ¿Tienes alguna pregunta o necesitas asistencia con nuestro software de facturación electrónica?
                            <br />
                            Estamos aquí para ayudarte.
                        </p>
                    </HeaderInfo>
                    {error.trim() !== "" && <AlertError key="danger" variant="danger">
                        {error}
                    </AlertError>}

                    {success.trim() !== "" && <AlertSuccess key="success" variant="success">
                        {success}
                    </AlertSuccess>}

                    <div className="row" style={{ paddingBottom: "30px" }}>
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                            <Form>
                                <label className="font13">Nombre completo:</label>
                                <input value={name} type="text" id="fname" name="fname" className="font20 extraBold" onChange={onChangeName} />
                                <label  className="font13">Número de teléfono:</label>
                                <input value={phoneNumber} type="text" id="phone" name="phone" className="font20 extraBold" onChange={onChangePhoneNumber} />
                                <label className="font13">Correo:</label>
                                <input value={email} type="text" id="email" name="email" className="font20 extraBold" onChange={onChangeEmail} />
                                <label className="font13">Asunto:</label>
                                <div>
                                    <select id="subject" name="subject" className="font20 extraBold select" style={{ marginBottom: 24 }} onChange={onChangeSubject}>
                                        <option value="suscription">Quiero una suscripción</option>
                                        <option value="error">Tengo un error con la aplicación</option>
                                        <option value="account">Tengo un error con mi cuenta</option>
                                        <option value="remove_account">Quiero eliminar mi cuenta</option>
                                        <option value="other">Otro</option>
                                    </select>
                                </div>
                                <label className="font13">Mensaje:</label>
                                <textarea value={message} rows="4" cols="50" type="text" id="message" name="message" className="font20 extraBold" onChange={onChangeMessage} />
                            </Form>
                            <SumbitWrapper className="flex">
                                <ButtonInput type="submit" value="Enviar mensaje" className="pointer animate radius4" onClick={sendContactMessage} />
                            </SumbitWrapper>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 flex">
                            <div style={{ width: "50%" }} className="flexNullCenter flexColumn">
                                <ContactImgBox>
                                    <img src={ContactImg1} alt="office" className="radius6" />
                                </ContactImgBox>
                                <ContactImgBox>
                                    <img src={ContactImg2} alt="office" className="radius6" />
                                </ContactImgBox>
                            </div>
                            <div style={{ width: "50%" }}>
                                <div style={{ marginTop: "100px" }}>
                                    <img src={ContactImg3} alt="office" className="radius6" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Wrapper>
    </>
}

const AlertError = styled.section`
width: 100%;
border: 1px solid red;
background-color: #ff00004D;
padding 10px;
border-radius: 4px
`;


const AlertSuccess = styled.section`
width: 100%;
border: 1px solid green;
background-color: #00ff004D;
padding 10px;
border-radius: 4px
`;

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  padding: 70px 0 30px 0;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Form = styled.form`
  padding: 70px 0 30px 0;
  input,
  textarea {
    width: 100%;
    background-color: transparent;
    border: 0px;
    outline: none;
    box-shadow: none;
    border-bottom: 1px solid #707070;
    height: 30px;
    margin-bottom: 30px;
  }
  textarea {
    min-height: 100px;
  }
  @media (max-width: 860px) {
    padding: 30px 0;
  }
`;
const ButtonInput = styled.input`
  border: 1px solid #2BA8C4;
  background-color: #2BA8C4;
  width: 100%;
  padding: 15px;
  outline: none;
  color: #fff;
  :hover {
    background-color: #0B093B;
    border: 1px solid #0B093B;
    color: #fff;
  }
  @media (max-width: 991px) {
    margin: 0 auto;
  }
`;
const ContactImgBox = styled.div`
  max-width: 180px; 
  align-self: flex-end; 
  margin: 10px 30px 10px 0;
`;
const SumbitWrapper = styled.div`
  @media (max-width: 991px) {
    width: 100%;
    margin-bottom: 50px;
  }
`;
