import React from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
import CloseIcon from "../../assets/svg/CloseIcon";
import Logo from "../../assets/img/ic_logo_blue.png"
import { Link as ReactRouterLink } from 'react-router-dom'


export default function Sidebar({ sidebarOpen, toggleSidebar, isHome }) {

  const openBlog = () => {
    window.open("https://tinpos.com.co/blog")
  }

  return (
    <Wrapper className="animate whiteBg" sidebarOpen={sidebarOpen}>
      <SidebarHeader className="flexSpaceCenter whiteBg">
        <div className="flexNullCenter">
        {isHome ? <Link className="pointer flexNullCenter title" to="home" smooth={true}>
            <img src={Logo} alt="Tin Pos: Software Pos #1 en Colombia" style={{height: "36px"}}/>
            <h1 style={{ marginLeft: "15px", marginTop: "8px" }} className="font20 extraBold">
              Tin Pos - Colombia 🇨🇴
            </h1>
          </Link> : <ReactRouterLink className="pointer flexNullCenter title" to={"/"}>
            <img src={Logo} alt="Tin Pos: Software Pos #1 en Colombia" style={{height: "36px"}}/>
            <h1 style={{ marginLeft: "15px", marginTop: "8px" }} className="font20 extraBold">
              Tin Pos - Colombia 🇨🇴
            </h1>
          </ReactRouterLink>}
        </div>
        <CloseBtn onClick={() => toggleSidebar(!sidebarOpen)} className="animate pointer">
          <CloseIcon/>
        </CloseBtn>
      </SidebarHeader>

      {isHome && <UlStyle className="flexNullCenter flexColumn">
        <li className="semiBold font15 pointer">
          <Link
            onClick={() => toggleSidebar(!sidebarOpen)}
            activeClass="active"
            className="purpleColor"
            style={{ padding: "10px 15px" }}
            to="home"
            spy={true}
            smooth={true}
            offset={-60}
          >
            Inicio
          </Link>
        </li>
        <li className="semiBold font15 pointer">
          <Link
            onClick={() => toggleSidebar(!sidebarOpen)}
            activeClass="active"
            className="purpleColor"
            style={{ padding: "10px 15px" }}
            to="services"
            spy={true}
            smooth={true}
            offset={-60}
          >
            Características
          </Link>
        </li>
        <li className="semiBold font15 pointer">
          <Link
            onClick={() => toggleSidebar(!sidebarOpen)}
            activeClass="active"
            className="purpleColor"
            style={{ padding: "10px 15px" }}
            to="projects"
            spy={true}
            smooth={true}
            offset={-60}
          >
            Vistas
          </Link>
        </li>
        {/*<li className="semiBold font15 pointer">
          <Link
            onClick={() => toggleSidebar(!sidebarOpen)}
            activeClass="active"
            className="whiteColor"
            style={{ padding: "10px 15px" }}
            to="blog"
            spy={true}
            smooth={true}
            offset={-60}
          >
            Precios
          </Link>
  </li>*/}
        <li className="semiBold font15 pointer">
          <Link
            onClick={() => toggleSidebar(!sidebarOpen)}
            activeClass="active"
            className="purpleColor"
            style={{ padding: "10px 15px" }}
            to="pricing"
            spy={true}
            smooth={true}
            offset={-60}
          >
            Precios
          </Link>
        </li>
        <li className="semiBold font15 pointer">
          <ReactRouterLink
            onClick={() => toggleSidebar(!sidebarOpen)}
            activeClass="active"
            className="purpleColor"
            style={{ padding: "10px 15px" }}
            to="/contacto"
          >
            Contacto
          </ReactRouterLink>
        </li>
        <li className="semiBold font15 pointer">
          <ReactRouterLink
            onClick={() => toggleSidebar(!sidebarOpen)}
            activeClass="active"
            className="purpleColor"
            style={{ padding: "10px 15px" }}
            to={{pathname: "blog/"}} target="_blank"
          >
            Blog
          </ReactRouterLink>
        </li>
      </UlStyle>}
    </Wrapper>
  );
}

const Wrapper = styled.nav`
  width: 400px;
  height: 100vh;
  position: fixed;
  top: 0;
  right: ${(props) => (props.sidebarOpen ? "0px" : "-400px")};
  z-index: 9999;
  @media (max-width: 400px) {
    width: 100%;
  }
`;
const SidebarHeader = styled.div`
  padding: 6px 20px;
`;
const CloseBtn = styled.button`
  border: 0px;
  outline: none;
  background-color: transparent;
  padding: 10px;
`;
const UlStyle = styled.ul`
  padding: 40px;
  li {
    margin: 20px 0;
  }
`;
